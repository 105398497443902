<template>
  <div id="app">

    <!-- 载入页面 -->
    <div class="init-loading" v-if="!ready">
      <div class="logo-box">
        <img src="../asset/logo.png" alt="">
      </div>
    </div>

    <!-- 头部标题栏 -->
    <div class="header shadow">
      <p>华海健康防伪溯源查验中心</p>
    </div>

    <!-- 防伪查验tab -->
    <div v-if="ready && tab == 1">
      <!-- 轮播图 -->
      <div class="banner">
        <img :src="scan_config.img[0]" alt="" />
      </div>

      <!-- 防伪查验 -->
      <div class="info-table text-align-center">
        <p class="title">防伪查验</p>
        <div class="card shadow">
          <p>您所查询的产品码为</p>
          <p class="c-g t-bold">{{ code }}</p>
          <p class="c-g t-bold" v-if="search_success">
            <img src="../asset/success.png" alt="">
            {{ content }}
          </p>
          <p class="describe" v-if="search_success">{{ contents }}</p>

          <input v-if="!search_success" class="token-input" v-model="token" placeholder="请输入产品验证码" type="text">
          <div v-if="!search_success" @click="search" class="button bgc-g">查验</div>
        </div>
      </div>

      <!-- 查验记录 -->
      <div class="info-table" v-if="search_success">
        <p class="title">查验记录</p>
        <div class="card shadow table">

          <div class="row head">
            <div class="item" style="width:20%;">查验次数</div>
            <div class="item" style="width:40%;">查验时间</div>
            <div class="item" style="width:40%;">IP所属</div>
          </div>
          <div class="row" v-for="(item, index) in record" v-bind:key="index">
            <div class="item" style="width:20%;">第{{ index + 1 }}次</div>
            <div class="item" style="width:40%;">{{ item.created_at }}</div>
            <div class="item" style="width:40%;">{{ item.city }}</div>
          </div>

        </div>
      </div>
    </div>

    <!-- 溯源信息tab -->
    <div v-if="ready && tab == 2">
      <!-- 产品主图 -->
      <div class="banner">
        <div class="card">
          <img :src="trace_info.product_img[0]" alt="" />
        </div>
      </div>

      <!-- 溯源信息 -->
      <div class="info-table">
        <p class="title">溯源信息</p>
        <div class="card">
          <div class="table">
            <div class="row border" v-for="(item,index) in trace_info.mes" v-bind:key="index">
              <div class="item" style="width:33.3333%;color:#c5c5c5;">{{ item[0] }}</div>
              <div class="item" style="width:66.6666%;">{{ item[1] }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品详情 -->
      <div class="info-table">
        <p class="title">产品详情</p>
        <div class="card">
          <div class="v-html" v-html="trace_info.product_introduce"></div>
        </div>
      </div>
    </div>

    <!-- 华海健康tab -->
    <div v-if="ready && tab == 3">
      <!-- 产品详情 -->
      <div class="info-table">
        <div class="card">
          <div class="table">

            <!-- 企业logo -->
            <div class="flex">
              <img class="company-logo" :src="company_info.logo" alt="">
            </div>

            <!-- 企业名称 -->
            <div class="row border">
              <div class="item" style="width:33.3333%;color:#c5c5c5;">公司名称</div>
              <div class="item" style="width:66.6666%;">{{ company_info.name }}</div>
            </div>

            <!-- 公司地址 -->
            <div class="row border">
              <div class="item" style="width:33.3333%;color:#c5c5c5;">公司地址</div>
              <div class="item" style="width:66.6666%;">{{ company_info.address }}</div>
            </div>

            <!-- 客服热线 -->
            <div class="row border">
              <div class="item" style="width:33.3333%;color:#c5c5c5;">客服热线</div>
              <div class="item" style="width:66.6666%;">{{ company_info.phone }}</div>
            </div>

            <!-- 公司简介 -->
            <div class="row border">
              <div class="item" style="width:33.3333%;color:#c5c5c5;">公司简介</div>
              <div class="item" style="width:66.6666%;">{{ company_info.introduce }}</div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- 底部导航栏 -->
    <div class="tabbar text-align-center">
      <div class="item" @click="changeTab(1)">
        <img src="../asset/1a.png" alt="" v-if="tab == 1" />
        <img src="../asset/1.png" alt="" v-else />
        <p class="title">防伪查验</p>
      </div>
      <div class="item" @click="changeTab(2)" v-if="ready">
        <img src="../asset/2a.png" alt="" v-if="tab == 2" />
        <img src="../asset/2.png" alt="" v-else />
        <p class="title">溯源信息</p>
      </div>
      <div class="item" @click="changeTab(3)" v-if="ready">
        <img src="../asset/3a.png" alt="" v-if="tab == 3" />
        <img src="../asset/3.png" alt="" v-else />
        <p class="title">华海健康</p>
      </div>
    </div>

    <!-- 弹窗 -->
    <div class="model text-align-center" v-if="error_type">
      <div class="table card">
        <img class="logo mb-2" src="../asset/error.png" alt="">
        <p class="message">{{ error_text }}</p>
        <div class="button bgc-r mt-2" @click="error">返回</div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      ready: false,
      tab: 1,
      param: {},
      uuid: '',
      code: '',
      company_info: {},
      role: {},
      scan_config: {},
      trace_info: {},
      token: '',
      error_type: false,
      error_text: '您输入的验证码与产品码不符，请确认后重新输入。',
      search_success: false,
      content: '',
      contents: '',
      record: [],
    }
  },
  methods: {
    getUrlParam() {
      var url = window.location.href;             //获取当前url
      var dz_url = url.split('#')[0];                //获取#/之前的字符串
      var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
      var cs_arr = cs.split('&');                    //参数字符串分割为数组
      cs = {};
      for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      return cs;
    },

    //18668157
    search() {
      var that = this;
      if (this.token == '') {
        that.error('请填写二维码下方的验证码进行查询。');
        return null;
      }
      this.$http.get('https://cloud.sharp-eyes.cn/mp/get-search-info-by-uuid-and-token?uuid=' + this.uuid + '&token=' + this.token).then(function (res) {
        if (res.data.code == 500) {
          that.error(res.data.message);
        }
        if (res.data.code == 200) {
          that.content = res.data.data.content;
          that.contents = res.data.data.contents;
          that.record = res.data.data.record;
          that.search_success = true;
        }
      });
    },
    error(error_text) {
      this.error_text = error_text;
      this.error_type = !this.error_type;
    },
    changeTab(index) {
      if (index != this.tab) {
        this.tab = index;
      }
    }
  },
  created() {
    var that = this;
    this.uuid = this.getUrlParam().uuid;
    this.$http.get('https://cloud.sharp-eyes.cn/mp/get-scan-res-by-uuid-and-token?company_id=53&uuid=' + this.uuid).then(function (res) {
      if (res.data.code == 500) {
        that.error(res.data.message);
      } else {
        that.company_info = res.data.data.company_info;
        that.role = res.data.data.role;
        that.scan_config = res.data.data.scan_config;
        that.trace_info = res.data.data.trace_info;
        that.code = res.data.data.code;
        that.ready = true;
      }
    });
  }
}
</script>

<style>
* {
  font-family: 'sans-serif,Microsoft YaHei';
}

div {
  box-sizing: border-box;
}

html {
  background: rgb(245, 245, 245);
}

body {
  margin: 0;
}

.shadow {
  box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.1);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 60px;
  padding-bottom: 120px;
}

.header {
  background: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  letter-spacing: 2px;
  font-weight: bold;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  level: 1;
}

.banner {
  width: 100%;
  padding: 15px 15px 0px;
}

.banner img {
  width: 100%;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
}

.tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 15px;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  level: 1;
  justify-content: center;
}

.tabbar>.item {
  width: 33.333%;
}

.tabbar>.item>img {
  height: 28px;
  width: 28px;
}

.tabbar>.item>.title {
  margin: 0;
  font-size: 14px;
}

.info-table {
  padding: 15px;
}

.info-table>.title {
  margin: 0 0 8px;
  text-align: left;
  letter-spacing: 1px;
  font-size: 14px;
}

.card {
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 6px;
}

.card p {
  margin: 0 0 8px;
}

.bgc-g {
  background-color: rgb(0, 158, 71);
}

.bgc-r {
  background-color: rgb(212, 22, 22);
}

.c-g {
  color: rgb(0, 158, 71);
}

.t-bold {
  font-weight: bold;
}

.token-input {
  font-size: 14px;
  border: none;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  line-height: 14px;
  height: 36px;
  width: 80%;
  margin-top: 15px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.button {
  width: 80%;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 6px;
  height: 36px;
  box-shadow: 0 0 5px 3px rgba(0, 158, 71, 0.1);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 4px;
  font-weight: bold;
}

.model {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  level: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.model>.table {
  width: 80%;
}

.model>.table>.logo {
  height: 68px;
  width: auto;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.message {
  letter-spacing: 1px;
  padding: 0 15px;
}

.describe {
  font-size: 12px;
  color: #c5c5c5;
}

.table {}

.table>.row {
  display: flex;
  width: 100%;
}

.table>.row.border{
  padding: 15px 0;
  border-bottom: 1px solid #eee ;
}

.table>.row.border:last-child{
  border-bottom: none !important;
}

.table>.row>.item {
  font-size: 12px;
  text-align: left;
  line-height: 24px;
}

.table>.row.head {
  color: #c5c5c5;
}

.content {
  width: 100%;
  padding: 15px 15px 0px;
}

.v-html{
  
}

.v-html img{
  max-width: 100%;
}

.text-align-center{
  text-align: center;
}

.company-logo{
  width: 50%;
  height: auto;
  margin: 15px auto;
}

.flex{
  display: flex;
}

.card>p>img{
  height: 1em;

}

.init-loading{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgb(255, 255, 255);
}

.init-loading>.logo-box{
  display: block;
  width: auto;
  width: 50%;
}

.init-loading>.logo-box>img{
  width: 100%;
}
</style>
